@use "styles/globals.scss" as *;
$transitionDuration: 300ms;

.button {
  text-align: center;
  border-radius: 12px;
  display: flex;
  background: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  z-index: 2;
  align-items: center;
  justify-content: center;

  &.landing {
    display: flex;
    position: relative;
    height: 60px;
    padding: 0 $margin;
    transition: all 250ms ease;
    border: 3px solid $lightRed;
    border-radius: 30px;
    background: transparent;
    color: $lightRed;
    font-size: 1.13rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    cursor: pointer;
    appearance: none;
    align-items: center;
    justify-content: center;

    @include mobile {
      font-size: 1.14rem;
      letter-spacing: 0.89px;
    }

    &:hover {
      background-color: rgba($lightRed, 0.1);
    }
  }

  > svg {
    margin-right: 24px;
  }

  &.small {
    width: 72px;
    height: 36px;
    border-radius: 6px;

    @include fontLinkSmall;
  }

  &.medium {
    width: 89px;
    height: 48px;
    border-radius: 8px;

    @include fontLinkLarge;
  }

  &.large {
    width: 100px;
    height: 60px;

    @include fontLinkLarge2;
  }

  &.highlight,
  &.default {
    @include cssVar(background-color, primary-highlight);
    @include cssVar(color, tertiary-white);
  }

  &.highlight-borders {
    border-width: 2px;
    border-style: solid;

    @include cssVar(border-color, primary-highlight);
    @include cssVar(color, primary-highlight);

    &:hover {
      @include cssVar(background-color, primary-highlight);
      @include cssVar(color, tertiary-white);
    }
  }

  &.highlight-transparent {
    border: none;

    @include cssVar(color, primary-highlight);

    &:hover {
      background-color: transparent;

      @include cssVar(color, primary-highlight);
    }
  }

  &.pricesavings {
    color: white;

    @include cssVar(background-color, tertiary-pricesavings);
  }

  &.ui {
    border-width: 1px;
    border-radius: 6px;
    border-style: solid;
    height: 36px;
    font-weight: normal;

    @include cssVar(border-color, secondary-ui-middle);
    @include cssVar(color, primary-dark);
    @include fontLabelSmall;
    @include hoverAccent(border-color);

    > svg {
      margin-right: 0;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  &.social {
    border-width: 2px;
    border-style: solid;

    @include cssVar(border-color, primary-dark);

    &.large {
      width: 148px;
      height: 60px;

      @include fontLinkLarge2;
    }

    &.medium {
      width: 137px;
      height: 48px;

      @include fontLinkLarge;
    }
  }

  &[disabled] {
    border: none;

    @include cssVar(color, tertiary-white);
    @include cssVar(background-color, secondary-ui-middle);

    &:hover {
      @include cssVar(background-color, secondary-ui-middle);
    }
  }
}

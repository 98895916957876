@use "styles/globals.scss" as *;
.refer {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: auto;
  top: 50%;
  padding: $margin * 2;
  border-radius: $margin / 2;
  transform: translateY(-50%);

  @include cssVar(background-color, tertiary-white);

  .close {
    position: absolute;
    top: $margin * 0.75;
    right: $margin * 0.75;
    background: none;
    cursor: pointer;
    padding: $margin / 4;
    width: $margin * 0.75;
    height: $margin * 0.75;
    box-sizing: content-box;
    border: 1px solid var(--colour-primary-highlight);
    border-radius: $margin / 4;

    svg {
      width: $margin * 0.75;
      height: $margin * 0.75;

      g {
        @include cssVar(fill, primary-highlight);
      }
    }
  }

  .heading {
    width: 100%;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: $margin;

    input {
      padding: $margin / 2 !important; // stylelint-disable-line
    }

    button {
      height: 48px;

      @include fontHeadingSmall;
    }

    .loading {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }

  .error {
    padding: 12px 8px;
    color: $colorTertiaryError;
    border: 2px solid $colorTertiaryError;
    border-radius: 8px;
    text-align: center;
  }
}

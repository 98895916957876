@use "styles/globals.scss" as *;
.userMenu {
  position: relative;

  .icons {
    display: inline-flex;

    @include mobile {
      margin-left: $margin / 4;
    }

    .icon {
      display: flex;
      position: relative;
      margin-right: $margin / 2;
      align-items: center;

      &[href='/wishlist'] {
        margin-left: $margin / 4;
      }

      svg {
        fill: none;

        @include cssVar(stroke, primary-dark);
      }

      .count {
        position: absolute;
        top: 3px;
        right: -3px;
        width: 12px;
        height: 12px;
        font-size: 10px;
        text-align: center;
        border-radius: 8px;

        @include cssVar(background-color, primary-dark);
        @include cssVar(color, tertiary-white);
      }

      &.noCircle {
        svg {
          circle {
            display: none;
          }
        }
      }
    }

    svg {
      fill: none;

      @include cssVar(stroke, primary-dark);
    }

    .wishlist,
    .cart {
      position: relative;

      > a {
        display: flex;
        position: relative;
        width: 40px;
        align-items: center;

        .count {
          position: absolute;
          right: 0;
          width: 16px;
          height: 16px;
          padding-top: 3px;
          font-size: 10px;
          text-align: center;

          @include cssVar(color, tertiary-white);
        }
      }
    }

    > button {
      display: flex;
      align-items: center;

      span {
        padding: 4px 10px 0 6px;
      }

      .chevron {
        transition: 0.2s all ease-in-out;

        &[data-modalopen='true'] {
          transform: rotateX(180deg);
        }

        svg path {
          fill: none;

          @include cssVar(stroke, primary-dark);
        }
      }
    }
  }

  .signin {
    a {
      padding: 11px 18px;
    }
  }
}

.background {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(#000000, 0.1);
  z-index: 2;
  animation: fadeIn 100ms ease-in;

  &[data-modalopen='true'] {
    display: block;
  }
}

.userModal {
  position: absolute;
  right: 0;
  width: 312px;
  margin-top: $margin / 2;
  padding: $margin $margin * 1.5 $margin * 1.5;
  float: right;
  transition: 0.2s all ease-in-out;
  border-radius: 12px;
  opacity: 0;
  z-index: 99;
  animation: fadeIn 100ms ease-in;

  @include dropShadow;
  @include cssVar(background-color, tertiary-white);

  @include mobile {
    padding: $margin * 0.75 $margin;
  }

  &[data-modalopen='true'] {
    opacity: 1;
  }

  .name {
    .fullname {
      @include fontHeadingMedium2;
      @include cssVar(color, primary-dark);
    }

    .email {
      @include fontBodyLarge;
      @include cssVar(color, primary-middle);
    }
  }

  .horizontalLine {
    margin: $margin 0 $margin * 0.5;
    border-bottom: solid 1px;

    @include cssVar(border-bottom-color, secondary-ui-middle);

    @include mobile {
      margin: $margin * 0.5 0 $margin * 0.25;
    }
  }

  .links {
    display: grid;

    a:not([class]) {
      width: 100%;
      margin: $margin * 0.5 0;

      @include fontBodyLarge;
      @include cssVar(color, primary-dark);

      @include mobile {
        margin: $margin * 0.25 0;
      }
    }

    button,
    a {
      width: 193px; // logout button
      height: 36px;
      border-radius: 6px;
    }

    a {
      margin: $margin * 0.5 0 $margin;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

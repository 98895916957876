@use "styles/globals.scss" as *;
.bottomBar {
  display: flex;
  border-top: solid 1px;
  padding: $margin / 2 0;
  text-align: right;
  align-items: center;

  @include mobile {
    padding: 0 $margin / 4;
    height: 48px;
  }

  @include cssVar(border-top-color, secondary-ui-middle);

  form {
    margin-right: auto;
  }

  input {
    border: none;
    width: 100%;
  }

  .links {
    display: flex;
    justify-content: flex-end;
    padding-right: $margin / 4;

    a {
      margin-right: $margin / 2;

      &:last-child {
        margin-right: 0;
      }

      @include fontBodyMedium;

      &.active {
        @include fontBodyMediumBold;
      }
    }
  }
}

@use "styles/globals.scss" as *;
.categoriesSelect {
  display: flex;
  justify-content: flex-end;
  position: relative;

  > button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 115px;
    height: 36px;
    padding-left: $margin / 2;
    border-radius: 6px;
    appearance: none;
    position: relative;
    cursor: pointer;

    @include cssVar(color, primary-dark);
    @include cssVar(border-color, secondary-ui-middle);
    @include cssVar(background-color, tertiary-white);
    @include hoverAccent(border-color);

    &::before {
      content: '';
      position: absolute;
      background: transparent;
      background-repeat: no-repeat;
      left: 80%;
      top: 40%;
      height: 7px;
      width: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s all ease-in-out;
      background-image: url('~images/icons/selectArrow.svg');
    }
  }

  .background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    animation: fadeIn 100ms ease-in;
    background: rgba(#000000, 0.1);
    z-index: 3;

    @include mobile {
      display: none;
    }
  }

  .modal {
    height: 240px;
    width: 683px;
    position: absolute;
    z-index: 5;
    left: 0;
    display: grid;
    grid-template-columns: 245px 437px;
    border-radius: 12px;
    margin-top: 50px;
    opacity: 0;
    transition: 0.2s all ease-in-out;
    animation: fadeIn 100ms ease-in;

    @include cssVar(background-color, tertiary-white);
    @include dropShadow;

    @include mobile {
      display: none;
    }

    .left {
      border-right: 1px solid;
      display: flex;
      flex-direction: column;
      padding-top: $margin;

      @include cssVar(border-color, secondary-ui-middle);

      button {
        border: none;
        border-radius: 0;
        display: flex;
        justify-content: flex-start;
        padding-left: $margin;

        @include cssVar(color, primary-dark);
        @include fontBodyLarge;

        &[data-active='true'] {
          font-weight: bold;

          @include cssVar(color, primary-accent);
        }

        &:hover {
          background: none;
        }
      }
    }

    .right {
      margin-top: $margin;
      margin-left: $margin;
      display: flex;
      flex-direction: column;

      .top {
        display: flex;
        align-items: center;
        margin-bottom: $margin / 2;

        h2 {
          @include fontHeadingMedium;
        }

        a {
          margin-left: 20px;

          @include fontBodySmallBold;
          @include cssVar(color, primary-highlight);
        }
      }

      .categories {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(auto-fit, minmax(20px, 1fr));

        a {
          @include fontBodyLarge;
        }
      }
    }
  }

  &[data-open='true'] {
    > button {
      &::before {
        transform: rotateX(180deg);
      }
    }

    .background {
      display: block;
    }

    .modal {
      opacity: 1;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@use "styles/globals.scss" as *;
.header {
  position: sticky;
  top: 0;
  z-index: 3;
  max-width: unset;
  margin: auto;

  &[data-e2e='true'] {
    position: relative !important; // stylelint-disable-line declaration-no-important
  }

  @include cssVar(background-color, tertiary-white);

  .logo {
    max-height: 24px;

    img {
      height: 100%;
      max-height: 40px;
    }

    @include mobile {
      max-height: 16px;
      max-width: 33%;

      img {
        max-height: 16px;
      }
    }
  }

  .mobile {
    .top {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 $margin / 2;

      button {
        padding: 12px;
      }

      .logo {
        max-width: 100%;

        image {
          width: 100%;
        }
      }
    }
  }

  .desktop {
    display: grid;
    grid-template: auto 1fr/1fr;
    grid-gap: 14.5px 0;
    max-width: $maxContainerWidth;
    margin: auto;
    justify-content: space-between;
    padding: $margin / 2 20px $margin / 2 $margin;

    @include lt-large-desktop {
      padding: $margin / 2;
    }

    .topLinks {
      width: 100%;
      text-align: right;

      a {
        font-size: 1rem;
        font-weight: normal;
        margin-left: $margin;

        @include cssVar(color, primary-middle);
      }
    }

    .menuBar {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      grid-gap: 6px;

      > div {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
      }

      .logo {
        margin-top: 10px;
        max-height: 40px;
      }
    }

    a {
      &.active {
        @include fontBodySmallBold;
      }
    }
  }
}

@use "styles/globals.scss" as *;
@mixin dataColor {
  &[data-color='orange'],
  &[data-color='green'],
  &[data-color='red'],
  &[data-color='blue'] {
    border-width: 2px;
  }

  &[data-color='orange'] {
    box-shadow: 0 0 8px 0 rgba($colorTertiaryAccent, 0.29);

    @include cssVar(border-color, tertiary-accent);
  }

  &[data-color='green'] {
    box-shadow: 0 0 8px 0 rgba($colorTertiaryPricesavings, 0.29);

    @include cssVar(border-color, tertiary-pricesavings);
  }

  &[data-color='red'] {
    box-shadow: 0 0 8px 0 rgba($colorTertiaryError, 0.29);

    @include cssVar(border-color, tertiary-error);
  }

  &[data-color='blue'] {
    box-shadow: 0 0 8px 0 rgba($colorPrimaryAccent, 0.29);

    @include cssVar(border-color, primary-accent);
  }
}

.field {
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    position: absolute;
    top: 22px;
    left: 23px;
    z-index: 1;
  }

  .error {
    margin: 0 0 $margin * 0.5;
    padding: 12px 8px;
    color: $colorTertiaryError;
    border: 2px solid $colorTertiaryError;
    border-radius: 8px;
  }

  input {
    flex: none;
    order: 0;
    flex-grow: 0;
    border: 1px solid;
    border-radius: 8px;
    width: 100%;
    height: 96px;
    padding: $margin * 2 20px $margin;
    margin-bottom: $margin / 2;

    @include fontBodyXL;
    @include cssVar(border-color, secondary-ui-middle);
    @include dataColor;
  }

  &.small.small {
    .error {
      margin: $margin * 0.5 0 0;
    }

    > input {
      height: unset;
      min-height: unset;
      padding: 0 $margin;
      margin: 0;

      @include fontBodyMedium;
    }

    label {
      position: initial;

      @include fontBodySmallBold;
    }
  }

  // Better field styling as label will wrap if too long (and uses relative instead of absolute)
  &.newStyles.newStyles {
    position: relative;
    min-height: 96px;
    height: fit-content;
    border: 1px solid;
    border-radius: 8px;
    margin-bottom: $margin / 2;
    padding: 22px 20px $margin;
    background-color: $colorSecondaryPageBg;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include cssVar(border-color, secondary-ui-middle);
    @include dataColor;

    label {
      position: relative;
      top: unset;
      left: unset;
    }

    input {
      flex: none;
      order: 0;
      flex-grow: 0;
      width: 100%;
      height: unset;
      border: none;
      outline: none;
      margin: 0;
      padding: 0;
      padding-block: 0;
      padding-inline: 0;

      &[data-color='orange'],
      &[data-color='green'],
      &[data-color='red'],
      &[data-color='blue'] {
        border-width: 0;
        box-shadow: none;
        border-color: transparent;
      }
    }
  }
}

@use "styles/globals.scss" as *;
.footerContainer {
  margin-top: $margin * 2.5;

  @include mobile {
    margin-top: 88px;
  }

  .shape {
    display: flex;
    position: relative;
    margin: -150px;
    transform: scaleX(-1);
    z-index: -1;
    justify-content: center;
    align-items: center;

    @include mobile {
      display: none;
    }
  }

  .footer {
    box-shadow: 0 -10px 150px #65545466;
    position: relative;

    @include cssVar(background-color, tertiary-white);

    .inner {
      margin: 0 auto;
      padding-left: 20px;

      @include maxContentWidth;

      @include mobile {
        padding: 10px $margin;
      }

      @include gt-mobile {
        padding-top: 69px;
      }

      .socialLinks {
        display: grid;
        grid-template-columns: repeat(auto-fit, 30px);
        grid-gap: 0 21px;

        @include mobile {
          grid-template-rows: min-content;
        }

        @include gt-mobile {
          grid-column: span 4;

          h3 {
            display: none;
          }
        }

        h3 {
          @include fontHeadingMedium2;

          @include mobile {
            margin-bottom: 14px;
            grid-column: span 4;
          }
        }

        svg {
          @include cssVar(fill, primary-dark);
        }
      }

      .top {
        display: grid;
        grid-template-columns: auto 1fr;

        @include mobile {
          padding: $margin * 1.5 0 0;
          grid-template-columns: unset;
        }

        .left {
          display: grid;
          padding-right: 100px;
          grid-template-columns: repeat(auto-fit, minmax(24px, 1fr));
          grid-gap: 20px;
          grid-template-rows: min-content;

          @include mobile {
            display: none;
          }

          .logo {
            width: 100%;
            margin-bottom: 20px;
            grid-column: span 15;
            grid-row: 1;

            img {
              max-height: 30px;
              object-fit: contain;
            }
          }
        }

        .right {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));

          @include mobile {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 0 $margin;
          }
        }
      }

      .bottom {
        display: grid;
        margin-top: 20px;
        height: 67px;
        border-top: 1px solid;
        align-items: center;
        grid-template-columns: 1fr auto;

        @include mobile {
          margin-top: 10px;
          padding: 18px 0 10px;
          grid-template-columns: auto auto;
          height: unset;
          align-items: end;
        }

        @include cssVar(border-top-color, secondary-ui-middle);

        .left {
          @include fontBodyXS;

          @include gt-mobile {
            display: flex;
          }

          p,
          a,
          span {
            @include fontBodyXS;

            @include cssVar(color, primary-middle);
          }

          p {
            margin: 0 $margin / 4 0 0;
          }
        }

        .logo {
          display: grid;
          align-items: center;

          a {
            @include fontBodyXS;
            @include cssVar(color, primary-middle);

            @include mobile {
              display: flex;
              align-items: flex-end;
              justify-content: flex-end;
            }
          }

          svg {
            position: relative;
            top: 6px;
            left: -6px;
            width: 94px;
            height: 25px;

            @include mobile {
              position: relative;
              bottom: 3px;
              left: 0;
              top: 2px;
              width: 74px;
            }
          }
        }
      }
    }

    .referralLink {
      cursor: pointer;
    }
  }
}

@use "styles/globals.scss" as *;
.searchBox {
  position: relative;

  input {
    padding: 0 20px 0 45px !important; // stylelint-disable-line
    border: solid 2px;
    border-radius: 6px;
    font-size: 1.1429rem;

    @include mobile {
      padding: 5px 10px 5px 40px !important; // stylelint-disable-line
    }

    @include cssVar(color, primary-dark);
    @include cssVar(border-color, secondary-ui-middle);
    @include hoverAccent(border-color);

    &::placeholder {
      @include cssVar(color, secondary-ui-middle);
    }
  }

  .icon {
    position: absolute;
    height: 100%;
    left: 15px;
    z-index: 2;
    display: flex;
    align-items: center;

    svg path {
      @include cssVar(fill, secondary-ui-middle);
    }

    @include mobile {
      padding-bottom: 2px;
      left: 5px;

      svg {
        height: 24px;
        width: 24px;
      }
    }
  }
}

@use "styles/globals.scss" as *;
@use 'globals.scss' as *;

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

html {
  font-size: 14px;
}

body {
  overflow-x: hidden;
  margin: 0;

  @include cssVar(background-color, secondary-page-bg);

  &[data-noscroll='true'] {
    overflow: hidden;
    max-height: 100vh;
  }

  &[data-static-body-padding='true'] {
    margin-bottom: 192px;
  }

  &.maxwidth {
    margin: auto;
    max-width: $maxContainerWidth;
  }

  &[data-static-bottom-padding='true'] {
    margin-bottom: 184px;
  }

  &[data-static-bottom-padding-small='true'] {
    margin-bottom: 117px;
  }

  main {
    min-height: calc(100vh - $minHeaderHeight + $minFooterHeight);

    &[data-platform='true'] {
      max-width: unset;
      margin: auto;
      overflow-x: hidden;

      h1 {
        @include fontHeadingXXL;
      }

      h3 {
        @include fontHeadingLarge;
      }

      h4 {
        @include fontHeadingMedium;
      }

      p,
      li {
        margin: 0;

        @include fontBodyLarge;

        @include mobile {
          @include fontBodyMedium;
        }
      }
    }

    &[data-platform='false'] {
      h1 {
        font-size: 3.7rem;
        color: $darkBlue;

        @include mobile {
          font-size: 2.43rem;
          letter-spacing: 1.13px;
        }
      }

      h2 {
        font-size: 2.5rem;

        @include mobile {
          font-size: 1.5rem;
          letter-spacing: 0.63px;
        }
      }

      h3 {
        font-size: 1.7rem;

        @include mobile {
          font-size: 1.3rem;
          letter-spacing: 0.46px;
        }
      }

      h4 {
        font-size: 1.35rem;

        @include mobile {
          font-size: 1.15rem;
        }
      }

      p {
        font-size: 1.71rem;
        line-height: 2.58rem;
        font-weight: normal;
        color: $darkBlue;
        margin: 0;

        @include mobile {
          letter-spacing: 0.25px;
        }
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin: 0;
}

label {
  display: inline-block;

  &[disabled] {
    cursor: not-allowed;
  }
}

select {
  &[disabled],
  &:disabled {
    cursor: not-allowed;
  }
}

input[type='text'],
input[type='email'],
input[type='tel'],
input[type='number'] {
  &[data-valid='false'] {
    border-color: $lightRed;

    &:focus {
      border-color: $lightRed;
      outline: none;
      box-shadow: 0 0 10px $lightRed;
    }
  }
}

.errorContainer {
  text-align: center;
  padding: 40vh 0;
}

a {
  text-decoration: none;

  @include cssVar(color, primary-dark);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none; // stylelint-disable-line
  margin: 0;
}

.ReactModal__Overlay.ReactModal__Overlay {
  z-index: 3;
  background-color: rgba(black, 0.6) !important; // stylelint-disable-line

  > div {
    background: none !important; // stylelint-disable-line
    border: none !important; // stylelint-disable-line
  }
}

.ReactModal__Content.ReactModal__Content {
  @include mobile {
    inset: 0 !important; // stylelint-disable-line
  }
}

.ReactModal__Body--open,
body[no-scroll='true'] {
  overflow: hidden;
  height: 100vh;
}

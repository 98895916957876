@use "styles/globals.scss" as *;
.menuBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 18px;
  margin-right: 18px;

  form {
    height: 100%;

    input {
      height: 36px !important; // stylelint-disable-line
    }
  }

  .dropdownMenu {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: white;
    width: max-content;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid transparent;

    @include cssVar(border-color, secondary-ui-middle);
  }

  .headerLink {
    position: relative;

    &:hover {
      .dropdownMenu {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  @include lt-large-desktop {
    .categoriesSelect {
      display: none;
    }
  }

  a {
    @include fontBodyMedium;
    @include cssVar(color, primary-dark);
    @include hoverAccent(color);

    &.active {
      @include fontBodyMediumBold;
    }
  }
}

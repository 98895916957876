@use "styles/globals.scss" as *;
.linkSection {
  display: flex;
  flex-direction: column;
  height: 100%;

  .parentLink {
    margin-bottom: 20px;

    @include fontHeadingMedium2;
    @include cssVar(color, primary-dark);
  }

  .childLink {
    margin-bottom: 26px;

    @include fontLinkMedium;
    @include cssVar(color, primary-middle);
  }
}

@use "styles/globals.scss" as *;
.headerLink {
  position: relative;

  @include mobile {
    margin-left: $margin / 4;
  }

  > button {
    display: flex;
    align-items: center;

    span {
      padding: 0 6px 0 0;
    }

    .chevron {
      transition: 0.2s all ease-in-out;

      &[data-modalopen='true'] {
        transform: rotateX(180deg);
      }

      svg path {
        fill: none;

        @include cssVar(stroke, primary-dark);
      }
    }
  }
}

.background {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  animation: fadeIn 100ms ease-in;

  &[data-modalopen='true'] {
    display: block;
  }
}

.dropdownModal {
  position: absolute;
  right: 0;
  min-width: 200px;
  margin-top: $margin / 2;
  padding: $margin * 0.5 $margin;
  float: right;
  transition: 0.2s all ease-in-out;
  border-radius: 12px;
  opacity: 0;
  z-index: 5;
  animation: fadeIn 100ms ease-in;

  @include dropShadow;
  @include cssVar(background-color, tertiary-white);

  @include mobile {
    margin-top: 0;
    padding: $margin * 0.75 $margin;
  }

  &[data-modalopen='true'] {
    opacity: 1;
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: $margin * 0.5;

    a {
      width: 100%;
    }
  }
}

.active.active {
  font-weight: 600;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
